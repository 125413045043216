<template>
  <a-layout class="con">
    <a-layout-sider theme="light" v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div :class="{'logo-wrap': true, center: collapsed}">
        <img class="logo" :src="appConfig.app.imgPath + 'image/common/' + (collapsed ? 'icon_logo.png' : 'logo_name.svg')"/>
      </div>
      <a-menu theme="light" mode="inline" v-model:selectedKeys="selectedKeys">
        <a-menu-item v-for="menu in menus" :key="menu.id">
          <user-outlined/>
          <span>{{ menu.name }}</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header">
        <div>
          <menu-unfold-outlined
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
          />
          <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)"/>
          <label class="title">{{appConfig.app.title}}</label>
        </div>

        <div>
          <label class="op-item">管理员</label>
          <a-button class="op-item" type="link" @click="onSignOut">退出</a-button>
        </div>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view v-if="isSigned">
        </router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons-vue';
import appConfig from "@/app.config";
import {ref, onMounted, defineComponent} from 'vue';
import routes from "../router";
import useSign from '@/hook/useSign';
import {useRouter} from 'vue-router';
import {urlMap} from "@/utils/uriMap";

export default defineComponent({
  components: {
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  setup() {
    const router = useRouter();
    const {checkSignStatus, signOut} = useSign();
    const menus = ref([]);
    const selectedKeys = ref([]);
    const isSigned = ref(false);

    // 静默登录
    onMounted(() => {
      signInSilent();
    })

    const signInSilent = () => {
      checkSignStatus(true)
          .then(res => {
            if (res.isSigned) {
              menus.value = routes.find(x => x.id === 'menus').children;
              selectedKeys.value.push(menus.value[0].id)
              isSigned.value = true;
            } else {
              router.push(urlMap.signIn);
            }
          })
          .catch(err => {
            console.log(err);
            router.push(urlMap.signIn);
          })
    }

    // 退出
    const onSignOut = () => {
      signOut().then(() => router.push(urlMap.signIn));
    }

    return {
      appConfig,
      isSigned,
      menus,
      selectedKeys,
      collapsed: ref(false),
      onSignOut,
    };
  },
});
</script>

<style scoped>
.con {
  width: 100%;
}

.header {
  display: flex;
  background: #fff;
  padding: 0 16px 0 0;
  justify-content: space-between;
}

.trigger {
  font-size: 18px;
  line-height: 70px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.op-item {
  margin-left: 16px;
  color: #333;
}

.logo {
  height: 32px;
  margin: 16px;
}

.content {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: unset;
}

.logo-wrap {
  padding-left: 2px;
}

.logo-wrap.center {
  text-align: center;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
</style>