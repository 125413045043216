import axios from "axios";
import appConfig from "@/app.config";

// axios.defaults.baseURL = appConfig.app.apiURL;
// axios.defaults.headers.utility['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


const request = () => {
}

request.axios = axios.create({
    baseURL: appConfig.app.apiURL,
});

request.get = function(url, conf) {
    return request.axios.get(url, conf);
};

request.post = function(url, data, conf) {
    return request.axios.post(url, data, conf);
};

request.put = function(url, data, conf) {
    return request.axios.put(url, data, conf);
};

request.delete = function(url, conf) {
    return request.axios.delete(url, conf);
};

request.setAuthToken = function(authToken) {
    request.axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
};

// Add a request interceptor
request.axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
request.axios.interceptors.response.use(function (response) {
    return Promise.resolve(response);
}, function (error) {
    // Do something with response error
    return Promise.resolve(error.response);
});


export default request
