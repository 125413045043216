import {createRouter, createWebHistory} from 'vue-router';
import singleLayout from "@/layout/singleLayout";
import compositeLayout from "@/layout/compositeLayout";

/**
 * 无需权限判断，可直接访问路由
 */
const staticRouterMap = [
  {
    id: 'single',
    path: '/',
    component: singleLayout,
    children: [
      {
        id: 'blank',
        name: '默认',
        path: '/',
        component: () => import('@/views/signIn/signIn.vue'),
      },
      {
        id: 'signIn',
        name: '登录',
        path: '/signin',
        component: () => import('@/views/signIn/signIn.vue'),
      }
    ]
  },
]

const dynamicRouterMap = [
  {
    id: 'menus',
    path: '/',
    component: compositeLayout,
    children: [
      {
        id: 'user',
        name: '用户管理',
        path: '/user',
        component: () => import('@/views/user/user.vue'),
      }
    ]
  },
]

const routes = [...staticRouterMap, ...dynamicRouterMap]

const router = createRouter({
  mode: 'history',
  history: createWebHistory('/promo/'),
  routes: routes
})

export default routes

export function setRouter(app) {
  app.use(router);
}