import {
    Layout,
    Menu,
    PageHeader,
    Form,
    Button,
    Input,
    Select,
    Divider,
    Radio,
    Table,
    Modal,
    Drawer,
    Card,
    Tag,
    Tooltip,
    Empty,
    DatePicker,
    Spin
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

export function setLib(app) {
    app.use(Layout);
    app.use(Menu);
    app.use(PageHeader);
    app.use(Form);
    app.use(Button);
    app.use(Input);
    app.use(Select);
    app.use(Divider);
    app.use(Radio);
    app.use(Table);
    app.use(Modal);
    app.use(Drawer);
    app.use(Card);
    app.use(Tag);
    app.use(Tooltip);
    app.use(Empty);
    app.use(DatePicker);
    app.use(Spin);
}
