export const apiMap = {
    // 登录
    signIn: 'user/signin',
    signOut: 'user/signout/:userId',
    // 用户
    searchPromoUsers: 'promo/user/search',
    getUserDetail: 'promo/user/detail/:userId',
    updateUser: 'promo/user/info',
    importTemplate: 'promo/user/import/file',
    exportData: 'promo/user/export/file',
    // 短信
    getSmsTemplates: 'promo/sms/template/list',
    sendSms: 'promo/sms/send',
    checkUnreadReply: 'promo/user/sms/unread/check',
    updateUserSms: 'promo/user/sms/info',
    // 用户备注
    addUserNote: 'promo/user/note/info',
    // 销售
    searchSalesmen: 'salesman/search',
}

export const urlMap = {
    // 登录
    signIn: 'signin',
    // 用户管理
    user: 'user',
}