import {reactive} from 'vue';

export default function useGlobalData() {
    const user = reactive({
        id: '',
        name: '',
        isSigned: false,
    })

    return {
        user,
    }
}