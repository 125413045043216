import {createApp} from 'vue'
import App from './App.vue'
import {setLib} from './lib'
import {setRouter} from "./router";
import '@/utils/expansion';

const app = createApp(App);
setLib(app);
setRouter(app);

app.mount('#app');

