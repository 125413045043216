<template>
  <ConfigProvider :locale="zhCN">
    <router-view/>
  </ConfigProvider>
</template>

<script>
import {provide, defineComponent} from 'vue';
import {ConfigProvider} from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import useGlobalData from "@/hook/useGlobalData";

export default defineComponent({
  components: {
    ConfigProvider,
  },
  setup() {
    // 提供全局变量
    provide('globalData', useGlobalData());

    return {
      zhCN,
    }
  },
});
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  display: flex;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.ant-page-header {
  padding: 0 !important;
}

.ant-notification-notice-description {
  white-space: pre-line;
  word-break: break-word;
}
</style>
