const createConfig = () => {
    const isProd = ["production", "prod"].includes(process.env.NODE_ENV);

    if (!isProd) {
        // 开发环境
        return {
            app: {
                sysCode: 'ankh',
                title: '安卡短信获客系统',
                apiURL: 'http://localhost:9090/api/',
                imgPath: 'https://ankh-data.oss-cn-beijing.aliyuncs.com/',
                imgPath_b: 'http://localhost/ankh/'
            },
        };
    } else {
        // 生产环境
        return {
            app: {
                sysCode: 'ankh',
                title: '安卡短信获客系统',
                apiURL: 'https://ankh.vip/api/',
                imgPath: 'https://ankh-data.oss-cn-beijing.aliyuncs.com/',
            },
        };
    }
};

const appConfig = createConfig();

export default appConfig
