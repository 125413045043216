import {inject} from "vue";
import request from "@/utils/request";
import {apiMap} from "@/utils/uriMap";

export default function useSign() {
    const TOKEN_NAME = 'ankh-token-admin';
    const globalData = inject('globalData');

    const isSigned = () => {
        return globalData.user.isSigned;
    }

    const checkSignStatus = (() => {
        // 记录当前是否在尝试登录，重复
        let isSigning = false;

        return function (trySign) {
            if (isSigning) {
                // 如果正在登录中，则拒绝
                return Promise.reject("isSigning");
            }

            // 获取当前登录状态
            const _isSigned = isSigned();

            // 未登录，且尝试登录
            if (!_isSigned && trySign) {
                isSigning = true;

                // 通过 token 静默登录
                return signInByToken()
                    .then(res => {
                        isSigning = false;
                        return Promise.resolve({res, isSigned: globalData.user.isSigned});
                    })
                    .catch(err => {
                        console.log(err);
                        isSigning = false;
                        return Promise.reject(err);
                    })
            } else {
                return Promise.resolve({isSigned: _isSigned})
            }
        }
    })()

    /**
     * 通过 token 登录（静默登录）
     * @returns {Promise<void>|*}
     */
    const signInByToken = () => {
        const token = localStorage.getItem(TOKEN_NAME);
        if (token) {
            return signIn({
                mode: 30,
                token: token,
                signInRole: '103',
            })
        } else {
            return Promise.resolve();
        }
    }

    /**
     * 登录
     * @param params
     */
    const signIn = (params) => {
        return request.post(apiMap.signIn, params)
            .then(res => {
                return signResult(res);
            })
            .catch(err => {
                console.log(err);
                return Promise.reject(err);
            })
    }

    /**
     * 登录结果处理
     * @param res
     * @returns {Promise<unknown>}
     */
    const signResult = res => {
        switch (res.status) {
            case 200:
                res.msg = '登录成功';
                globalData.user = res.data.data.user;
                globalData.user.isSigned = true;
                request.setAuthToken(res.data.data.token)
                localStorage.setItem(TOKEN_NAME, res.data.data.token);
                break;
            case 201:
                res.msg = '注册成功';
                globalData.user = res.data.data.user;
                globalData.user.isSigned = true;
                request.setAuthToken(res.data.data.token)
                localStorage.setItem(TOKEN_NAME, res.data.data.token);
                break;
            case 400:
                res.msg = '参数错误';
                break;
            case 401:
                res.msg = '用户名或密码错误';
                break;
            case 403:
                res.msg = '登录被拒绝';
                break;
            case 404:
                res.msg = '用户不存在';
                break;
            case 409:
                res.msg = '用户已存在';
                break;
            case 500:
                res.msg = '服务器错误';
                break;
            default:
                res.msg = '未知错误';
                break;
        }

        return Promise.resolve(res);
    }

    /**
     * 注销
     * @returns {*}
     */
    const signOut = () => {
        return request.put(apiMap.signOut.format({userId: globalData.user.id}))
            .then(res => {
                switch (res.status) {
                    case 204:
                        globalData.user = {
                            id: '0',
                            isSigned: false,
                        };

                        request.setAuthToken('');
                        localStorage.setItem(TOKEN_NAME, '');
                        return Promise.resolve(res);
                    case 400:
                        break;
                    case 500:
                        break;
                }
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    return {
        isSigned,
        checkSignStatus,
        signIn,
        signOut,
    }
}